import React from 'react'

import { Container, Text } from './styled'
type IProps = {
 element?: 'h1' | 'h2' |  'h3' | 'h4' | 'h5';
 className?: any;
}

const Title: React.FC<IProps> = (props) => {
  const { children, element, className} = props
  return (
      <div className={`${className} row`.trim()}>
        <div className="col-xs-12">
          <Container className="title">
            <Text as={element}>{children}</Text>
          </Container>
        </div>
      </div>
  )
}

export { Title }
