import styled from 'styled-components';

export const Container = styled.div`
    color: ${props => props.theme.COLORS.WHITE};
    width: 100%;
    height: 100%;
`;

export const BlockSpace = styled.div<{ witdth: string, height: string }>`
    display: block;
    width: ${props => props.witdth};
    height: ${props => props.height};
`

export const Main = styled.main`
    padding: 60px 30px;
    min-height: inherit;
    height: 100%;
    width: 100%;
`