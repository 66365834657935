import React, { useContext, useEffect } from 'react'
import Head from 'next/head'

import { Container, Main, BlockSpace } from './styled'
import { Title } from '../../components'

type IProps = {
    pageName: string
    title?: string
    highlight?: string;
    className?: any
}

const Page: React.FC<IProps> = (props) => {
    const {title = "", className = '', children, highlight = '', pageName} = props

    const mainText = highlight ? title.replace(highlight,'') : title
    const highlighted = title.indexOf(highlight)
    const highlightText = title.substring(highlighted)

    useEffect(() => {
        document.body.setAttribute('class',pageName)
    },[pageName])

    return (
        <Container className={className + ' container'}>
            <Head>
                <title>Kelvin silva{ title ? `: ${title}`: ''}</title>
                <meta name="description" content="Generated by create next app" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" href="/favicon.ico" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
            </Head>

            <Main>
                {title 
                    ? (
                        <>
                            <Title>
                                { mainText }
                                { highlighted ? <span>{highlightText}</span> : null }
                            </Title>
                            <BlockSpace  witdth="100%" height="20px"/>
                        </>
                    )
                    : null
                }


                {children}
            </Main>
        </Container>
    )
}

export { Page }
