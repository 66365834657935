import Particles from 'react-tsparticles';
import styled from 'styled-components';

import { Page } from '../../containers/Page'

export const Main = styled(Page)`
    main {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    #tsparticles{
        position: relative;
        z-index: 1
    }
`

export const PageParticles = styled(Particles)`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: #222;
`

export const Title = styled.h2`
    font-size: 60px;
    line-height: 74px;
    text-align: center;
    position: relative;
    z-index: 2;
`

export const Subtitle = styled.div`
    color: #aaa;
    font-size: 21px;
    font-weight: 300;
    margin: 5px 0;
    text-align: center;
    position: relative;
    z-index: 2;
`