import styled from 'styled-components';

export const Container = styled.div`
    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-bottom: 15px;
        font-size: 1.6rem;    
        z-index: 3;
        position: relative;
        display: inline-block;
        white-space: nowrap;
        &:after {
            position: absolute;
            opacity: .45;
            bottom: 0px;
            left: 100%;
            content: '';
            transform: translateX(-50%);
            height: 30px;
            width: 50px;
            background-image: -webkit-repeating-radial-gradient(center center,#5050f5,#5050f5 1px,transparent 0px,transparent 100%);
            background-size: 6px 6px;
            z-index: -1;
        }
    
        span{
            color: ${props => props.theme.COLORS.PRIMARY};
        }
    }
`

export const Text = styled.h2``