/* eslint-disable @next/next/no-sync-scripts */
import type { NextPage } from 'next'
import { motion } from 'framer-motion'
import { Main, Title, Subtitle } from './styled'
import React from 'react'


const Home: NextPage = () => {
  return (
      <Main pageName="home">
        <motion.div
          style={{ zIndex: 3 }}
          drag={true}
          dragConstraints={{
            top: -50,
            left: -50,
            right: 50,
            bottom: 50
          }} 
          initial="hidden" 
          animate="visible" 
          variants={{
            hidden: {
              scale: .8,
              opacity: 0,
            },
            visible: {
              scale: 1,
              opacity: 1,
              transition: {
                delay: .4
              } 
          }}
        }> 
        <Title>Kelvin Silva</Title>
        </motion.div>
        <motion.div
          style={{ zIndex: 3 }}
          drag={true}
          dragConstraints={{
            top: -50,
            left: -50,
            right: 50,
            bottom: 50
          }} 
          initial="hidden" 
          animate="visible" 
          variants={{
            hidden: {
              scale: .8,
              opacity: 0,
            },
            visible: {
              scale: 1,
              opacity: 1,
              transition: {
                delay: .4
              } 
          }}
        }>
          <Subtitle>Fullstack-developer</Subtitle>
        </motion.div>
      </Main>
  )
}

export default Home
